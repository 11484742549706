function commonInit() {

    // Pace.on("start", function(){
    //     $("#preloader").show();
    // });
    // Pace.on("done", function(){
    //     $("#preloader").hide();
    // });
    // paceOptions = {
    //     ajax: false,
    //     elements: {
    //         selectors: ['#block-1']
    //     }
    // };

    // $(".wrap").sectionsnap({
    //     delay         : 100,
    //     selector      : 'section[id^="block-"]',
    //     reference     : 1,
    //     animationTime : 600
    // });

    //caches a jQuery object containing the header element
    var header = $(".navbar-transparent");
    $(window).scroll(function() {
        var scroll = $(window).scrollTop();
        if (scroll >= 200) {
            header.removeClass('navbar-transparent').addClass("navbar-solid");
        } else {
            header.removeClass("navbar-solid").addClass('navbar-transparent');
        }
    });

    $('a.scroll, .scroll a').smoothScroll();


    $('.entry-share-btns a').on('click', function(e) {
      e.preventDefault();
      var link = jQuery(this).attr('href');
      var width = 840;
      var height = 464;
      var popupName = 'popup_' + width + 'x' + height;
      var left = (screen.width-width) / 2;
      var top = 100;
      var params = 'width=' + width + ',height=' + height + ',location=no,menubar=no,scrollbars=yes,status=no,toolbar=no,left=' + left + ',top=' + top;
      window[popupName] = window.open(link, popupName, params);
      if (window.focus){
        window[popupName].focus();
      }
      return true;
    });

    //$('#video-iframe').fitVids();

    //FUNCTION TO GET AND AUTO PLAY YOUTUBE VIDEO FROM DATATAG
    function autoPlayYouTubeModal() {
        var trigger = $("body").find('[data-toggle="modal"]');
        trigger.click(function () {
            var theModal = $(this).data("target"),
            videoSRC = $(this).attr("data-theVideo"),
            videoSRCauto = videoSRC + "?autoplay=1";
            $(theModal + ' iframe').attr('src', videoSRCauto);
            $(theModal + ' button.close').click(function () {
                $(theModal + ' iframe').attr('src', videoSRC);
            });
            $('.modal').click(function () {
                $(theModal + ' iframe').attr('src', videoSRC);
            });
        });
    }
    autoPlayYouTubeModal();

    // .modal-backdrop classes
    $(".modal-fullscreen").on('show.bs.modal', function () {
      setTimeout( function() {
        $(".modal-backdrop").addClass("modal-backdrop-fullscreen");
      }, 0);
    });
    $(".modal-fullscreen").on('hidden.bs.modal', function () {
      $(".modal-backdrop").addClass("modal-backdrop-fullscreen");
    });

    $('body').scrollspy({
        offset:0
    });

    //caches a jQuery object containing the header element
    var scrollHeader = $(".navbar-transparent");
    $(window).scroll(function() {
        var scroll = $(window).scrollTop();
        if (scroll >= 200) {
            scrollHeader.removeClass('navbar-transparent').addClass("navbar-solid");
        } else {
            scrollHeader.removeClass("navbar-solid").addClass('navbar-transparent');
        }
    });

    // Hide Header on on scroll down
    var didScroll;
    var lastScrollTop = 0;
    var delta = 5;
    var navbarHeight = $('.navbar').outerHeight();

    $(window).scroll(function(event){
        didScroll = true;
    });
    function hasScrolled() {
        var st = $(this).scrollTop();

        // Make sure they scroll more than delta
        if(Math.abs(lastScrollTop - st) <= delta){
           return;
        }

        // If they scrolled down and are past the navbar, add class .nav-up.
        // This is necessary so you never see what is "behind" the navbar.
        if (st > lastScrollTop && st > navbarHeight){
            // Scroll Down
            $('.navbar').removeClass('nav-down').addClass('nav-up');
        } else {
            // Scroll Up
            if(st + $(window).height() < $(document).height()) {
                $('.navbar').removeClass('nav-up').addClass('nav-down');
            }
        }

        lastScrollTop = st;
    }
    setInterval(function() {
        if (didScroll) {
            hasScrolled();
            didScroll = false;
        }
    }, 250);

    //search
    /* ======= Blog page searchbox ======= */
   /* Ref: http://thecodeblock.com/expanding-search-bar-with-jquery-tutroial/ */
    var submitIcon = $('.searchbox-icon');
    var inputBox = $('.searchbox-input');
    var searchBox = $('.searchbox');
    var isOpen = false;
    submitIcon.on('click', function(){
        if(isOpen === false){
            searchBox.addClass('searchbox-open');
            inputBox.focus();
            isOpen = true;
        } else {
            searchBox.removeClass('searchbox-open');
            inputBox.focusout();
            isOpen = false;
        }
    });

    submitIcon.mouseup(function(){
        return false;
    });
    searchBox.mouseup(function(){
        return false;
    });
    $(document).mouseup(function(){
        if(isOpen === true){
            $('.searchbox-icon').css('display','block');
            submitIcon.click();
        }
    });

    function buttonUp(){
        var inputVal = $('.searchbox-input').val();
        inputVal = $.trim(inputVal).length;
        if( inputVal !== 0){
            $('.searchbox-icon').css('display','none');
        } else {
            $('.searchbox-input').val('');
            $('.searchbox-icon').css('display','block');
        }
    }

    inputBox.keyup(function() {
        buttonUp();
    });

    //Make sure the "Go" button is not shown when resize the browser window from mobile to desktop
    $(window).resize(function(){
        $('.searchbox-icon').css('display','block');
        searchBox.removeClass('searchbox-open');
    });


}
