function carouselInit() {

  // var slider = $('div.slick').slick({
  // });
  var player = $('div.slick .slick-active.slide-video-bg').find('#video-player');
  if (player.length !==0){
      player.get(0).play();
  }

  // Lexical Scope of outside of callback to save prev slide
  //var prevSlide = undefined;

  // When change slide save current as last slide
  // NOTE: currentSlide and nextSlide are INT
  if ( $('.slick-current').hasClass( "slide-video-bg" ) ) {
      $('div.slick').on('beforeChange', function(event, slick, currentSlide, nextSlide){
          prevSlide = currentSlide;
          $("#slide-" + (nextSlide + 1)).find('video').get(0).play();
      });

      // When we land on new slide pause old slide
      // NOTE: currentSlide and nextSlide are INT
      $('div.slick').on('afterChange', function(event, slick, currentSlide, nextSlide){
          $("#slide-" + (prevSlide + 1)).find('video').get(0).pause();
      });
  }


  if ( $('div.block-bg').hasClass( ".video-bg-container" ) ) {
      $("video.video-bg").get(0).play();
  }
}
