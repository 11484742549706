/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        commonInit();
        // JavaScript to be fired on all pages
        $('a.scroll, .scroll a').smoothScroll();
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        //animate based on mouse movement
        $.fn.parallax = function ( resistance, mouse )
        {
          $el = $( this );
          TweenLite.to( $el, 0.2,
          {
            x : -(( mouse.clientX - (window.innerWidth/2) ) / resistance ),
            y : -(( mouse.clientY - (window.innerHeight/2) ) / resistance )
          });
        };

        $( document ).mousemove( function( e ) {
          $( '.home #block-99 #text' ).parallax( -60, e );
          $( '.home #block-99 #year' )	  .parallax( 20	, e );
          $( '.home #block-99 #ornate' )	  .parallax( 40	, e );
          $( '.home #block-99 #border' )	  .parallax( 60	, e );
        });

        //TweenMax.set('.home #ornate path',{ fill:"#28f77b"});
        TweenMax.staggerFrom('.home #year g', 1, { yoyo:true, repeat:-1, immediateRender: false, transformOrigin:"center center", autoAlpha: 0,scale: 0.2,fill:"#28f77b", ease: Power4.easeInOut  }, 1.5);
        TweenMax.staggerFrom('.home #text path', 1, { yoyo:true, repeat:-1, immediateRender: false, transformOrigin:"center center",  autoAlpha: 0,scale: 0.2, ease: Power4.easeInOut  }, 0.25);
        TweenMax.staggerFrom('.home #ornate path', 1, { yoyo:true, immediateRender: false, transformOrigin:"center center",  autoAlpha: 0,rotation: "+=90", ease: Power4.easeInOut  }, 0.05);
        //TweenMax.staggerFrom('.home #ornate path', 1, { fill:"#4ee7f2", ease: Power4.easeInOut  }, 0.05);

        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'has_carousel': {
      init: function() {
        // JavaScript to be fired on the about us page
        $('.slick').slick();
        carouselInit();
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
